header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; 
}

@media (min-width: 670px) {
  header {
    flex-direction: row;
    align-items: baseline; } }

.mainMenu {
  min-width: 320px;
  justify-content: space-around;
  display: flex; }

ul {
  list-style: none;
  line-height: 2;
  padding: 0; }

a {
  text-decoration: none; }

.title {
  width: 100%;
  margin: 1em 0; }

@media (min-width: 340px) {
  .title {
    width: 340px; } }

@media (min-width: 950px) {
  header {
    justify-content: space-around;
  }
}




  
  
