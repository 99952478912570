section {
  padding: 1rem;
  border: 2px solid #112147;
  margin: .5rem .5rem 0 0;
  transition-property: background-color, color;
  transition-duration: .5s;
  transition-timing-function: ease-out; }

section:hover, section:focus {
  background-color: #D9E0F1;
  transition-property: background-color, color;
  transition-duration: .3s;
  transition-timing-function: ease-out; }

.work, .clients {
  margin: 1.5em 0;
  justify-content: center; }

@media (min-width: 1044px) {
  .work, .clients {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
  section {
    min-width: 43vw; } }

.work ul {
  list-style: none;
  padding-left: 0;
  line-height: 1.3;
  padding-top: .5rem; }

.work h3 {
  margin-bottom: .5rem; }
